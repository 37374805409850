<template>
  <div class="is__relative">
    <div :class="`date-picker-${$.uid}`"></div>
    <basic-icon
      size="35"
      class="m-l-2 close-icon"
      @click="toggleDatePicker(true)"
      v-if="!useCustomTrigger">
      calendar
    </basic-icon>
    <vuetify-menu
      :attach="`.date-picker-${$.uid}`"
      transition="scale-transition"
      :close-on-content-click="false"
      v-model="showDatePicker"
      content-class="date-picker-wrapper"
      bottom
      right
      offset-y
      offset-overflow
      min-width="auto">
      <template #activator="{ on, attrs }">
        <icon-button
          v-if="iconActivated"
          test-id="date-picker-trigger"
          size="small"
          icon-size="16px"
          v-bind="attrs"
          v-on="on">
          calendar-blank
        </icon-button>
      </template>

      <vuetify-date-picker
        no-title
        ref="datePicker"
        range
        :color="computedColor"
        prebasic-icon="mdi-chevron-up"
        next-icon="mdi-chevron-down"
        @input="handleDateClick"
        v-model="selectedDates"
        :model-value="modelValue"></vuetify-date-picker>
    </vuetify-menu>
  </div>
</template>

<script>
import { VDatePicker as VuetifyDatePicker } from 'vuetify/lib/components/VDatePicker/VDatePicker.mjs';
import { IconButton, BasicIcon } from '@/components';
import { VMenu as VuetifyMenu } from 'vuetify/lib/components/VMenu/VMenu.mjs';
import { LuxonDateTimeFormats } from '@nova/core';

export default {
  // TODO: Input does not allow readonly mode, so using disabled to prevent manual input.
  // TODO: This could be confusing to users.  MIRANDA NEEDS READONLY FIELDS!
  components: { BasicIcon, IconButton, VuetifyDatePicker, VuetifyMenu },
  props: {
    /**
     * @model
     * Format LuxonDateTimeFormats.DateDashed
     */
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * The earliest date that can be selected - leave null to keep it open ended
     * Format LuxonDateTimeFormats.DateDashed
     */
    minDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * The latest date that can be selected - leave null to keep it open ended
     * Format LuxonDateTimeFormats.DateDashed
     */
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Use the calendar Icon button to activate datepicker rather than text input
     */
    iconActivated: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Format of date to display in the text field.  Use LuxonDateTimeFormats
     */
    displayFormat: {
      type: String,
      required: false,
      default: LuxonDateTimeFormats.MonthDayYearSlashed
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: null
    },
    timezone: {
      type: String,
      required: false,
      default: null
    },
    useCustomTrigger: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedColor() {
      return this.mirandaUtil.getTokenCssValue('color-text-primary');
    }
  },
  data() {
    return {
      showDatePicker: false,
      selectedDates: []
    };
  },
  methods: {
    handleDateClick(dateRange) {
      if (dateRange?.length > 1) {
        this.$emit('input', dateRange);
        this.toggleDatePicker(false);
      }
    },
    toggleDatePicker(val) {
      this.showDatePicker = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker-wrapper {
  padding-top: $m-spacing-2;
  padding-bottom: $m-spacing-4;
  background-color: #fff;
  max-width: max-content;
}

::v-deep {
  .v-btn--active {
    background-color: $m-color-text-primary;
    color: $m-color-text-inverted !important;

    .v-btn__content {
      &:before {
        content: ' ';
        height: 1px;
        background-color: white;
        width: 10px;
        position: absolute;
        bottom: -2px;
      }
    }
  }
  #date-picker-field {
    display: none;
  }
}
</style>
